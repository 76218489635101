import React from "react";
import WhyUs from "../components/WhyUs_Section";
import Unity_show from "../components/Unity_show";
import Business from "../components/Business_Section";
import Technology from "../components/Technology_Section";
import Achievement from "../components/Achievement_Section";
import Button from "../components/Button";
import svgImg from "../image/Home-img/BG_1.svg";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      {/*    Section_1 形象區    */}
      <div className="section_1">
        <img className="section_1_img" src={svgImg} alt="晨豐科技形象圖" />
        <div className="section_1_content">
          <p className="section_1_title">晨豐科技</p>
          <p className="section_1_secTitle">
            <span>V</span>ast<span>L</span>ife Technologies Inc.
          </p>
          <p className="section_1_thirdTitle">
            一個專業的地理資訊 <span>GIS技術整合</span> 服務公司
          </p>
          <Link to="/companyInfo">
            <Button text="公司簡介" />
          </Link>
        </div>
      </div>

      {/*    Section_2 WhyUs    */}
      <div className="section_2">
        <WhyUs />
      </div>

      {/*    Section_2.1 new 建築資訊檢視互動式應用展示    */}
      <div className="section_2">
        <Unity_show />
      </div>

      {/*    Section_3 業務範圍    */}
      <div className="section_3">
        <div className="section_3_overlay"></div>
        <Business />
      </div>

      {/*    Section_4 技術領域    */}
      <div className="section_4">
        <Technology />
      </div>

      {/*    Section_5 成果展示    */}
      <div className="section_5">
        <Achievement />
      </div>
    </>
  );
};

export default Home;
