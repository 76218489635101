import React from "react";

const Unity_show = () => {
  return (
    <div className="why">
      <div className="why_title">
        <div className="why_title_div"></div>
        <p className="why_title_text">互動式應用</p>
      </div>
      <div className="why_container_2">
        <div className="why_container_left">
          <p className="why_left_title">建築資訊檢視互動式應用系統</p>
          <p className="why_left_text">
            整合GIS+BIM+Unity之應用系統，可透過分類篩選及物件選取功能，查詢BIM量體之目標物件並查看物件相關屬性資訊，藉由系統功能之日照工具進行模擬建築遮蔭、使用剖切功能可深入剖析內部結構，內有量測功能、透視功能、飛行功能、檢視功能、建物各階段期程功能….等，可幫助高效展示BIM量體設計體驗及線上查詢BIM量體物件之即時資訊。
          </p>
        </div>
        <div className="why_container_2_right">
          <iframe
            width="86%"
            height="auto"
            src="https://www.youtube.com/embed/fbOjk6WmBLU?autoplay=1&mute=1&loop=1&playlist=fbOjk6WmBLU"
            title="建築資訊檢視互動式應用"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Unity_show;
